function calBTEpox(form) {
var len = eval(form.len.value);
var wid = eval(form.wid.value);
var thi = .125;
var o = 144;
var k = (len * wid) / o;  
var k = k / 12;
var k = Math.ceil(k);
var qk = k * 4;
var pk = k * 8;
if (k > 1) {var plural = 's';} else { var plural = '';}
if (k < 1) {var k = 1; var plural = ' (partial)';}
var btLink = 'https://www.wisebond.com/cart/21941119647849:' + k;
var buy = ''; 
document.getElementById("barWarn").innerHTML = buy;
form.kit.value = k + ' Kit' + plural;
form.kitqts.value = qk + ' Quarts';
form.kitpts.value = pk + ' Pints';
} 
delete len;
delete wid;
delete thi; 
delete o;
delete k;
delete plural;

function calEpox(form) {
var len = eval(form.len.value);
var wid = eval(form.wid.value);
var thi = eval(form.thi.value);
var ci = len * wid * thi;
var bf = ci / 144;
var kits = Math.ceil(bf / 2.4);
var gals = (bf / 2.4) * 1.5; 
var pts = gals * 8;
var pts = Math.round (pts * 10) / 10;
var qts = gals * 4;
var qts = Math.round (qts * 10) / 10;
var gals = Math.round(gals * 100) / 100;
var warn = 'CAUTION: Pouring more than 2 inches thick may increase the risks of drying defects. Do not use if desired thickness is less than 1/2 inch. If more than 2 inches of depth is needed, allow the product to dry for 24 hours before pouring consecutive layers of desired thickness.';
var buy = '<a href="https://www.wisebond.com/collections/home-page-collection/products/deep-pour-river-table-epoxy-resin-kit" class="buyItNow  animated bounceIn">Buy Now</a>';


form.pts.value = pts + ' Pts';
form.qts.value = qts + ' Qts';
form.gal.value = gals + ' Gal';
if (kits < 2) {
form.kit.value = kits + ' Kit';
} else {
form.kit.value = kits + ' Kits';
}

var buyLink = 'https://www.wisebond.com/cart/30894121648233:' + kits;
var buy = '';

if (thi < .5 || thi > 2) {
document.getElementById("deepWarn").innerHTML = buy + '<br />' + warn;
} else {
document.getElementById("deepWarn").innerHTML = buy;
}

delete len; 
delete wid;
delete thi;
delete buy;
delete gals;
  delete kits;
  delete warn;
  delete bf;
  delete ci;
}
